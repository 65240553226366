import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FormActualizeVisit from '../../components/form/FormActualizeVisit';
import usePatientDetails from '../../hooks/usePatientDetails';
import InfoPopup from '../../components/alerts/InfoPopup';
import { InfoIcon } from 'lucide-react';
import axios from 'axios';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ActualizeVisit: React.FC = () => {
  const [isIpAllowed, setIsIpAllowed] = useState<boolean | null>(null);
  const { clinicId, visitId } = useParams();
  const [showPatientFetchedAlert, setPatientFetchedAlert] = useState<boolean>(false);
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [actualizedStatus, setActualizedStatus] = useState<boolean | null>(null);
  const [uuid, setUuid] = useState<string | null>(null); 
  const navigate = useNavigate();

  // Destructure the return values of the usePatientDetails hook
  const { fetchedPatientDetails, loading, error } = usePatientDetails(uuid); 

  useEffect(() => {
    const initialize = async () => {
      try {
        // Check IP and fetch actualize data sequentially
        const ipCheckResult = await checkIp();
        if (ipCheckResult) {
          await fetchActualizeData();
        }
        console.log("fetchedPatientDetails", fetchedPatientDetails);
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    initialize();
  }, [visitId, fetchedPatientDetails]);  // Ensure this effect runs when fetchedPatientDetails changes

  const checkIp = async (): Promise<boolean> => {
    console.log("Checking IP...");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/check-ip`);
      if (response.status === 200) {
        console.log("IP allowed");
        setIsIpAllowed(true);
        return true; 
      } else {
        console.log("IP not allowed");
        setIsIpAllowed(false);
        return false; 
      }
    } catch (error) {
      console.error("Error checking IP:", error);
      setIsIpAllowed(false);
      return false; 
    }
  };

  const fetchActualizeData = async () => {
    if (!visitId) return; 
    try {
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/visit/actualize/${visitId}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
  
      if (response.status === 200) {
        const { actualized, uuid } = response.data;
        console.log("Data", response);
        if (actualized) {
          navigate(`/patient/screening/${uuid}`);
          return; 
        }
  
        setActualizedStatus(false);
        if (uuid) {
          setUuid(uuid);
        }
      }
    } catch (error) {
      console.error('Error fetching actualize data:', error);
      setActualizedStatus(false);
      navigate('/list'); 
    }
  };

  useEffect(() => {
    // Only set patient details once fetchedPatientDetails is available
    if (fetchedPatientDetails && uuid) {
      setPatientDetails(fetchedPatientDetails);
      setPatientFetchedAlert(true);
    }
  }, [fetchedPatientDetails, uuid]);  // Trigger when fetchedPatientDetails or uuid changes

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {isIpAllowed === null ? (
        <p className="text-gray-700">Checking access rights...</p>
      ) : isIpAllowed ? (
        <>
          {actualizedStatus === null || actualizedStatus === undefined ? (
            <p>Loading actualize status...</p>
          ) : !actualizedStatus ? (
            <div className="bg-gradient-to-br from-purple-400 to-blue-400 rounded-lg shadow-2xl p-8 w-full max-w-2xl"> 
              {!showPatientFetchedAlert && (
                <InfoPopup
                  color="pink"
                  boldText="Patient not found:"
                  text="Please register patient on Milkiway directly."
                  icon={InfoIcon}
                />
              )}
              {showPatientFetchedAlert && (
                <>
                  <InfoPopup
                    color="blue"
                    boldText="Patient found:"
                    text="Please actualize the visit by confirming patient details and selecting the package and visit date."
                    icon={InfoIcon}
                  />
                  <div className="modal-container bg-white p-8 mt-6 rounded-lg shadow-lg">
                    {loading ? (
                      <p>Loading patient details...</p> 
                    ) : (
                      <FormActualizeVisit
                        patientDetails={patientDetails}
                        clinicId={clinicId}
                        uuid={uuid} 
                      />
                    )}
                  </div>
                </>
              )}
            </div> 
          ) : (
            <p className="text-green-500">This visit has already been actualized.</p>
          )}
        </>
      ) : (
        <section className="bg-white dark:bg-gray-900">
          <div className="bg-gradient-to-br from-purple-400 to-blue-400 rounded-lg shadow-2xl p-8 w-full max-w-2xl">
            <div className="py-8 px-4 mx-auto max-w-screen-xl bg-white lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primaryColor-600 dark:text-primaryColor-500">
                  Error
                </h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                  Invalid IP address
                </p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                  You don't have access to this page. Please contact your administrator.
                </p>
                <a
                  href="#"
                  className="inline-flex text-white bg-primaryColor-600 hover:bg-primaryColor-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                >
                  Back to Homepage
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ActualizeVisit;
