import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import BreadcrumbPatient from '../../components/BreadcrumbPatient';
import Tabs from '../../components/Tabs';
import CardVisitList from '../../components/cards/CardListVisit';
import CommsLog from '../../components/communication/CommsLog';
import CheckList from '../../components/CheckList';
import useVisitAndPatientData from '../../hooks/useVisitAndPatientData';
import CardAddToList from '../../components/cards/CardAddToList';
import { useQuery } from '@tanstack/react-query';
import useFormData from '../../hooks/useFormData';

const Patient = () => {
  const { uuid } = useParams();
  const { visitData, patientData, loading } = useVisitAndPatientData(uuid);
  const {tabCompletionStatus} = useFormData(uuid);
  // Define the query for TabCompletionStatus (optional, if needed for other purposes)
  const { data: tabCompletionStatusCache } = useQuery({
    queryKey: ['form', uuid, 'TabCompletionStatus'],
    queryFn: () => useFormData(uuid),  // Pass the tabCompletionStatus directly from useFormData
    enabled: !!uuid,
  });
  
  const patientId = visitData ? visitData.PatientId : null;

  return (
    <>
      <div className="flex justify-between pt-4">
        <div>
          {patientData && (
            <BreadcrumbPatient
              name={patientData.name}
              nric={patientData.nric}
              daStatus={patientData.daStatus}
              daName={patientData.daName}
              age={patientData.age}
              gender={patientData.gender}
              race={patientData?.race}
            />
          )}
        </div>
        <div>
          {uuid && <Tabs uuid={uuid} tabCompletionStatus={tabCompletionStatusCache || tabCompletionStatus} />}
        </div>
      </div>
      <div className="mb-4 border-b-2 w-full border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
        {visitData && patientId && (
          <CheckList patientId={patientId} uuid={uuid} />
        )}
      </div>
      <div className="mb-4 flex gap-4">
        <div className="w-1/2 flex flex-col gap-4">
          <div className="border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
            {visitData && patientId && (
              <CardVisitList patientId={patientId} />
            )}
          </div>
          <div className="border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
            {visitData && patientId && (
              <CardAddToList patientId={patientId} />
            )}
          </div>
        </div>
        <div className="w-1/2 border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
          {visitData && patientId && (
            <CommsLog patientId={patientId} />
          )}
        </div>
      </div>
    </>
  );
};

export default Patient;
