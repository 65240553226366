import React, { useEffect, useState } from "react";
import axios from "axios";
import { Check } from "lucide-react";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CardQuestionnaire = ({ nric, onPatientDataSelect }) => {
    if(nric){
        console.log("nric in Card Questionnaire", nric);
        } 
    const [searchResults, setSearchResults] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null); // State variable to store the selected patient data
    const [questionnaireSet, setQuestionnaireSet] = useState(null);
    const [resultsWithNoVisitData, setResultsWithNoVisitData] = useState([]);

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                console.log("Starting to fetch questionnaires...");
    
                // Fetch questionnaires based on NRIC
                const questionnairesResponse = await axios.get(
                    `${REACT_APP_API_BASE_URL}/questionnaires/query?nric=${nric.toUpperCase()}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );
                console.log("Questionnaires response status:", questionnairesResponse.status);
    
                if (questionnairesResponse.status === 200) {
                    const questionnairesData = questionnairesResponse.data;
                    console.log("Questionnaires data received:", questionnairesData);
    
                    if (questionnairesData.length === 0) {
                        console.log("No questionnaires found for this NRIC.");
                    }
    
                    // Fetch visit data for each questionnaire concurrently
                    const visitRequests = questionnairesData.map((questionnaire) => {
                        console.log(`Fetching visit data for questionnaire with UUID: ${questionnaire.qUuid}`);
    
                        return axios.get(`${REACT_APP_API_BASE_URL}/visit/uuid/${questionnaire.qUuid}`, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            withCredentials: true,
                        })
                        .then(visitResponse => {
                            if (visitResponse.status === 200) {
                                questionnaire.visitDate = visitResponse.data.visitDate;
                                console.log(`Visit data found for questionnaire ${questionnaire.qUuid}:`, visitResponse.data.visitDate);
                            } else {
                                questionnaire.visitDate = null;
                                console.log(`No visit data found for questionnaire ${questionnaire.qUuid}`);
                            }
                        })
                        .catch((visitError) => {
                            questionnaire.visitDate = null;
                            console.error(`Error fetching visit data for ${questionnaire.qUuid}:`, visitError);
                        });
                    });
    
                    // Wait for all visit data to be fetched
                    await Promise.all(visitRequests);
                    console.log("All visit data fetched.");
    
                    // Filter out results with visit data and keep only those with no visit data
                    const resultsWithNoVisitData = questionnairesData.filter(
                        questionnaire => questionnaire.visitDate === null
                    );
                    console.log("Filtered results with no visit data:", resultsWithNoVisitData);
    
                    // Update the state with the filtered results
                    setResultsWithNoVisitData(resultsWithNoVisitData);
                    setSearchResults(resultsWithNoVisitData);
                } else {
                    console.log("Error: Received non-200 response from questionnaires query.");
                    setSearchResults([]);
                }
            } catch (error) {
                console.error("Error during questionnaires fetch:", error);
            }
        };
    
        if (nric) {
            console.log("NRIC provided:", nric);
            fetchQuestionnaires();
        } else {
            console.log("No NRIC provided.");
        }
    }, [nric]);
    

    // Function to format date
    const formatDateTime = (date) => {
        if (!date) return "No visit data";

        return new Date(date).toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    };

    // Function to determine row color
    const getRowColor = (index) => {
        return index % 2 === 0 ? "bg-gray-100" : "bg-gray-200";
    };

    const handlePatientSelect = (result, index) => {
        // Extract necessary data from the result object
        const { name, nric, qUuid } = result;

        // Set the selected patient using the extracted data
        setSelectedPatient({
            qUuid
        });

        // Call the callback function with the selected patient data
        onPatientDataSelect({
            qUuid
        });

        // Set the index of the selected row
        setQuestionnaireSet(index);
    };


    return (
        <table className="w-full pb-4 rounded-lg">
            <tbody>
                {resultsWithNoVisitData.length > 0 ? (
                    // If there are results with no visit data, display them
                    resultsWithNoVisitData.map((result, index) => {
                        const QLocalDateTimeString = formatDateTime(result.createdAt);
                        const visitDate = result.visitDate 
                        ? formatDateTime(result.visitDate) 
                        : "Questionnaire not associated with a visit date"; // Handle null visitDate display
        
                        return (

                            <tr
                                key={result.id}
                                className={`cursor-pointer text-black text-sm ${getRowColor(index)}`}
                                onClick={() => handlePatientSelect(result, index)}
                            >
                                  <td className="py-2 px-4">
                                    {questionnaireSet === index && <Check className="text-primaryColor-500 h-4 w-4" />}
                                </td>
                                <td className="py-2 px-2">
                                    <p>
                                        <strong>{result.name} </strong>
                                        ({result.nric})
                                    </p>
                                    <p>
                                        <strong>Questionnaire Timestamp:</strong>{" "}
                                        {QLocalDateTimeString}
                                    </p>
                                    <p>
                                        <strong>Questionnaire Uuid:</strong>{" "}
                                        {result.qUuid}
                                    </p>
                                    <p>
                                        {visitDate}
                                    </p>
                                </td>

                            </tr>
                        );
                    })
                ) : (
                    // If there are no results with no visit data, display a message
                    <tr>
                        <td className="py-2 px-2 text-left bg-black text-white text-sm">
                            Patient has not filled out any questionnaire that is not associated with a visit in the last 14 days.
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};


export default CardQuestionnaire;
