import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ToolbarButton from '../ToolbarButton';
import {
  Pencil,
  Copy,
  Check,
  Printer,
  Link2,
  AlertTriangle,
  ChevronDown,
  Ghost,
} from 'lucide-react';
import useTemplateCache from '../../hooks/useTemplateCache';
import InfoPopup from '../alerts/InfoPopup';
import LoadingPopup from '../LoadingPopup';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp';
import Menu from '../Menu';
import Tooltip from '../Tooltip';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

interface VisitData {
  id: number;
  createdAt: string;
  uuid: string;
  visitId: string;
  visitDate: string;
  healthScreeningPackageUuid?: string;
  healthScreeningPackageName?: string;
}

interface CardVisitListProps {
  patientId: number;
}

const getDownloadUrl = async ({ patientId, fileId }) => {
  try {
    const resp = await axios.get(
      `${REACT_APP_API_BASE_URL}/patients/${patientId}/files/id/${fileId}`,
      {
        withCredentials: true, // Include credentials (cookies) in the request
      },
    );
    if (resp.status === 404) {
      console.error('Data not found for this patient.');
      // You can return or set some state to indicate that no data was found.
      return null;
    }
    if (!resp || !resp.data) throw new Error('Missing data');
    return resp.data.signedUrl;
  } catch (err) {
    console.error('Error:', err);
  }
};

const CardVisitList: React.FC<CardVisitListProps> = ({ patientId }) => {
  const [visitData, setVisitData] = useState<VisitData[]>([]);
  const [reports, setReports] = useState({}); // To store presigned URLs per visit UUID
  // Initialize a state to track copy success for each item
  const [copyURLSuccesses, setCopyURLSuccesses] = useState<{
    [uuid: string]: boolean;
  }>({});
  const [copySuccesses, setCopySuccesses] = useState<{
    [uuid: string]: boolean;
  }>({});
  const [initialCardCount, setInitialCardCount] = useState(3); // Number of cards to display initially
  const { uuid: urlUUID } = useParams<{ uuid: string }>();
  const { data: templateData = {}, isLoading: templateLoading } =
    useTemplateCache();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackageUuid, setSelectedPackageUuid] = useState<string | null>(
    null,
  );

  const [healthScreeningPackages, setHealthScreeningPackages] = useState<any[]>(
    [],
  ); // Stores the list of packages
  const [isDisabled, setIsDisabled] = useState(false); // Track button state

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    options: any[];
  } | null>(null);

  useEffect(() => {
    const closeMenu = () => setContextMenu(null);
    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  const fetchVisitData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/visit/all/${patientId}`,
        { withCredentials: true },
      );

      const visitData = response.data;
      // console.log("visitData", visitData);
      // Update visitData with formatted registration date
      const updatedVisitData = visitData.map((item) => ({
        ...item,
        registrationDate: convertUTCToSGTimestamp(item.createdAt),
      }));

      // Sort the visits by visitDate (most recent first)
      const sortedVisitData = updatedVisitData.sort((a, b) => {
        // Parse the visitDate string to a Date object and compare them
        return (
          new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime()
        );
      });

      const enhancedVisitData = sortedVisitData.map((visit) => {
        if (visit.healthScreeningPackageUuid) {
          const matchedPackage = healthScreeningPackages.find(
            (hsPackage) =>
              hsPackage.healthScreeningPackageUuid ===
              visit.healthScreeningPackageUuid,
          );

          if (matchedPackage) {
            return {
              ...visit,
              healthScreeningPackageName:
                matchedPackage.healthScreeningPackageName,
            };
          }
        }

        return {
          ...visit,
          healthScreeningPackageName: 'No package selected',
        };
      });

      // Update state with the enhanced visit data
      setVisitData(enhancedVisitData);
    } catch (error) {
      console.error('Error fetching visit data:', error);
    } finally {
      setIsLoading(false); // Set loading to false after data fetch is done
    }
  };

  // Fetch visit data when either patientId or healthScreeningPackages changes
  useEffect(() => {
    if (patientId && healthScreeningPackages.length > 0) {
      fetchVisitData();
    }
  }, [patientId, healthScreeningPackages]);

  useEffect(() => {
    if (templateData.HealthScreeningPackage) {
      setHealthScreeningPackages(templateData.HealthScreeningPackage);
    }
  }, [templateData]);

  useEffect(() => {
    const fetchAllReports = async () => {
      const fetchedReports = {};

      for (let visit of visitData) {
        const { uuid } = visit;
        const report = await fetchPdfReport(uuid, patientId);

        console.log('Fetched report for uuid:', uuid, report);

        if (report && report.signedUrl) {
          fetchedReports[uuid] = report.signedUrl; // Corrected property name if 'signedUrl' is used
        }
      }

      // Log all fetched reports before updating state
      console.log('All fetched reports (presignedUrls):', fetchedReports);

      // Update state with all reports after the loop
      setReports(fetchedReports);
    };

    // Log the visitData to ensure it's being passed correctly
    console.log('VisitData:', visitData);

    if (visitData.length > 0) {
      fetchAllReports();
    }
  }, [visitData, patientId]); // Ensure this effect runs when visitData or patientId changes

  const handleRightClick = (event: React.MouseEvent, item: VisitData) => {
    event.preventDefault();

    const matchedPackage = healthScreeningPackages.find(
      (hsPackage) =>
        hsPackage.healthScreeningPackageUuid ===
        item.healthScreeningPackageUuid,
    );

    const healthScreeningOptions = healthScreeningPackages.map((hsPackage) => {
      const isHighlighted =
        matchedPackage &&
        hsPackage.healthScreeningPackageUuid ===
          matchedPackage.healthScreeningPackageUuid;
      return {
        label: hsPackage.healthScreeningPackageName,
        onClick: () =>
          handlePackageSelection(
            hsPackage.healthScreeningPackageUuid,
            item.uuid,
          ),
        isHighlighted,
      };
    });

    if (!matchedPackage) {
      healthScreeningOptions.push({
        label: 'No package selected',
        onClick: () => handlePackageSelection(null, item.uuid),
        isHighlighted: false,
      });
    }

    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      options: healthScreeningOptions,
    });
  };

  const handlePackageSelection = async (
    packageUuid: string | null,
    visitUuid: string,
  ) => {
    if (packageUuid && packageUuid !== selectedPackageUuid) {
      const visit = {
        uuid: visitUuid,
        healthScreeningPackageUuid: packageUuid,
        PatientId: patientId,
      };

      try {
        const visitResponse = await axios.post(
          `${REACT_APP_API_BASE_URL}/visit`,
          visit,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          },
        );

        // Trigger a page reload to reflect the updated state
        window.location.reload();
      } catch (error) {
        console.error('Error updating package:', error);
      }
    }
  };

  const handleCopyReportURL = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    // Construct the URL to be copied using string interpolation
    const reportURL = `${REACT_APP_FRONTEND_BASE_URL}/passwordcheck/${uuid}`;
    // Copy the URL to the clipboard
    const el = document.createElement('textarea');
    el.value = reportURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Set the copy success for the specific UUID to true
    setCopyURLSuccesses((prevCopyURLSuccesses) => ({
      ...prevCopyURLSuccesses,
      [uuid]: true,
    }));

    // Reset copySuccess after a short delay
    setTimeout(() => {
      setCopyURLSuccesses((prevCopyURLSuccesses) => ({
        ...prevCopyURLSuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  // Function to handle copying the UUID of the currently clicked item
  const handleCopyData = (uuid: string) => {
    // Copy the UUID to the clipboard
    const el = document.createElement('textarea');
    el.value = uuid;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Set the copy success for the specific UUID to true
    setCopySuccesses((prevCopySuccesses) => ({
      ...prevCopySuccesses,
      [uuid]: true,
    }));

    // Reset copySuccess after a short delay
    setTimeout(() => {
      setCopySuccesses((prevCopySuccesses) => ({
        ...prevCopySuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  const loadMore = () => {
    setInitialCardCount(initialCardCount + 5);
  };

  const navigate = useNavigate(); // Initialize the useHistory hook

  const handleEdit = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    event.stopPropagation(); // Stop event propagation
    navigate(`/patient/screening/${uuid}`);
  };

  const fetchPdfReport = async (uuid, patientId) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/patients/files/id/${patientId}/uuid/${uuid}`,
        { withCredentials: true },
      );

      if (response.status === 404) {
        console.error('File not found for the given UUID.');
        return null;
      }

      const { fileId } = response.data;
      console.log('File ID fetched:', fileId);

      // Second API call to fetch file details by fileId
      const fileResponse = await axios.get(
        `${REACT_APP_API_BASE_URL}/patients/${patientId}/files/id/${fileId}`,
        { withCredentials: true },
      );

      if (fileResponse.status === 404) {
        console.error('File details not found.');
        return null;
      }

      // Log the file details and the presignedUrl
      console.log('File details:', fileResponse.data);

      // Assuming that presignedUrl is part of fileResponse.data
      return fileResponse.data; // Assuming fileResponse.data contains the presignedUrl
    } catch (error) {
      console.error('Error fetching PDF report:', error);
      return null;
    }
  };

  const handleButtonClick = (uuid) => {
    const signedUrl = reports[uuid];
    if (signedUrl) {
      window.open(signedUrl, '_blank'); // Open the PDF in a new tab
    }
  };

  return (
    // <!-- Features -->
    <div className="rounded-lg">
      <LoadingPopup isLoading={isLoading} />
      {/* <!-- Grid --> */}
      <h3 className="text-xl font-semibold text-black dark:text-white px-2 py-3">
        Visit History
      </h3>
      {error && (
        <InfoPopup
          color="red"
          boldText="Insufficient data to generate the report:"
          text={errorMessage}
          icon={AlertTriangle}
        />
      )}
      {/* <!-- Tab Navs --> */}
      <nav className="grid" aria-label="Tabs" role="tablist">
        {visitData.slice(0, initialCardCount).map((item) => (
          <div
            className={`my-2 rounded-lg  border border-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover-bg-gray-700 ${
              item.uuid === urlUUID ? 'bg-blue-50' : ''
            }`}
            key={item.id}
          >
            {' '}
            <a
              href={`${REACT_APP_FRONTEND_BASE_URL}/patient/${item.uuid}`}
              className="block"
            >
              <div className="cursor-pointer py-4">
                <div className="px-6 flex items-center">
                  <h5 className="text-sm font-bold text-left text-gray-900 dark:text-white">
                    Visit Date:
                  </h5>
                  <p className="text-gray-700 text-xs pl-2 dark:text-white">
                    {convertUTCToSGTimestamp(item.visitDate)}
                  </p>
                  <div
                    className="pl-2"
                    onContextMenu={(e) => handleRightClick(e, item)} // Add right-click handler here
                  >
                    <span
                      className={`inline-flex items-center rounded-lg px-3 py-1 text-xs font-medium mr-2 mb-2 ${
                        item.healthScreeningPackageName ===
                        'No package selected'
                          ? 'text-black bg-gray-200'
                          : 'text-white bg-blue-500'
                      }`}
                    >
                      {item.healthScreeningPackageName}
                      {/* Conditionally disable or render chevron */}
                      {item.uuid === urlUUID && (
                        <ChevronDown className="w-4 h-4 ml-1" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="px-6 flex justify-between">
                  <div className="flex items-center">
                    <h5 className="inline-block text-sm font-bold text-left text-gray-900 dark:text-white">
                      Unique Visit ID:
                    </h5>
                    <p className="inline-block text-gray-700 text-xs pl-2 dark:text-white">
                      {item.uuid}
                    </p>
                  </div>
                </div>
                <div className="px-6 flex justify-between">
                  <div className="flex items-center">
                    <h5 className="inline-block text-sm font-bold text-left text-gray-900 dark:text-white">
                      Visit ID:
                    </h5>
                    <p className="inline-block text-gray-700 text-xs pl-2 dark:text-white">
                      {item.visitId}
                    </p>
                  </div>
                  <div className="flex">
                    <ToolbarButton
                      text="Copy Report URL"
                      icon={
                        !copyURLSuccesses[item.uuid] ? (
                          <Link2 className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyReportURL(e, item.uuid);
                      }}
                    />

                    {reports[item.uuid] ? (
                      <ToolbarButton
                        text="Open Report"
                        icon={<Printer className="w-4 h-4" />}
                        onClick={() => handleButtonClick(item.uuid)}
                      />
                    ) : (
                      <div className="flex items-center justify-center text-gray-500 text-sm">
                        <Tooltip text="No PDF report available">
                          <button
                            type="button"
                            className="p-2 ml-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                            disabled={true} // Properly set to true (or dynamically set based on condition)
                          >
                            <Ghost className="w-4 h-4" />
                          </button>
                        </Tooltip>
                      </div>
                    )}

                    <ToolbarButton
                      text="Copy Visit ID"
                      icon={
                        !copySuccesses[item.uuid] ? (
                          <Copy className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyData(item.uuid);
                      }}
                    />
                    <ToolbarButton
                      text="Edit Records"
                      icon={<Pencil className="w-4 h-4" />}
                      onClick={(e) => handleEdit(e, item.uuid)}
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </nav>
      {/* <!-- End Tab Navs --> */}
      {initialCardCount < visitData.length && (
        <div className="flex justify-center my-2">
          <button
            onClick={loadMore}
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Load more
            </span>
          </button>
        </div>
      )}

      {contextMenu && (
        <Menu
          x={contextMenu.x}
          y={contextMenu.y}
          options={contextMenu.options}
          onClose={() => setContextMenu(null)}
        />
      )}
    </div>
  );
};

export default CardVisitList;
