import React, { useEffect, useState } from 'react';
import { Plus, Check, Save, X, ChevronDown, BookKey,  } from 'lucide-react';
import ToolbarButton from './ToolbarButton';
import axios from 'axios';
import useTemplateCache from '../hooks/useTemplateCache';
import useVisitAndPatientData from '../hooks/useVisitAndPatientData';
import convertUTCToSGTimestamp from '../function/convertUTCToSGTimestamp';

const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const checkboxStyle = "checked:bg-[url('https://milkiway-public-images.s3.ap-southeast-1.amazonaws.com/check.svg')] appearance-none w-4 h-4 border border-gray-300 bg-gray-50 focus:ring-2 focus:ring-primaryColor-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primaryColor-500 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 rounded mr-2";
const textStyle = "text-sm text-gray-600 dark:text-white";
const textBoldStyle = "text-sm font-medium text-gray-900 dark:text-white";
const inputSelectorStyle = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

interface CheckListItem {
    category: string;
    tasks: { itemName: string; status: string }[];
}

interface CheckListProps {
    patientId: number | undefined;
    uuid: string;
    initialItems?: CheckListItem[];
}

const CheckList: React.FC<CheckListProps> = ({ patientId, uuid }) => {
    const [items, setItems] = useState<CheckListItem[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [newCategory, setNewCategory] = useState<string>('');
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [visitDate, setVisitDate] = useState<string>('');
    const { data: templateData = {}, isLoading: templateLoading } = useTemplateCache();
    const { visitData, loading: visitLoading } = useVisitAndPatientData(uuid);

    useEffect(() => {
        // Exit early if data is still loading
        if (templateLoading || visitLoading || !visitData) return;
    
        const fetchData = async () => {
            try {
                // Format the timestamp if `createdAt` is available
                if (visitData['visitDate']) {
                    const formattedTimestamp = convertUTCToSGTimestamp(visitData['visitDate']);
                    // console.log('Formatted Timestamp:', formattedTimestamp);
                    setVisitDate(formattedTimestamp);
                }
                // console.log('VisitData Checklist:', visitData.checkList);
                // console.log('templateData Checklist:', templateData["Checklist"]);

                // Set items from the template if available
                if (visitData.checkList) {
                    setItems(visitData.checkList);
                }
                else {
                    setItems(templateData["Checklist"]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [templateLoading, visitLoading, templateData, visitData]);
    

    const handleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleAddDialogOpen = () => {
        setShowAddDialog(true);
    };

    const handleAddDialogClose = () => {
        setShowAddDialog(false);
    };

    const handleAddNewItem = () => {
        if (inputValue && newCategory) {
            const newItem = { itemName: inputValue, status: 'unchecked' };

            const updatedItems = items.map((categoryItem) => {
                if (categoryItem.category === newCategory) {
                    return {
                        ...categoryItem,
                        tasks: [...categoryItem.tasks, newItem],
                    };
                }
                return categoryItem;
            });

            setItems(updatedItems);
            setInputValue('');
            handleSubmit();
            handleAddDialogClose();
        }
    };

    const toggleItem = (categoryIndex: number, taskIndex: number) => {
        const updatedData = [...items];
        updatedData[categoryIndex].tasks[taskIndex].status =
            updatedData[categoryIndex].tasks[taskIndex].status === 'unchecked' ? 'checked' : 'unchecked';

        setItems(updatedData);
    };

    const removeItem = (categoryIndex: number, taskIndex: number) => {
        const updatedData = [...items];
        updatedData[categoryIndex].tasks.splice(taskIndex, 1);

        setItems(updatedData);
    };

    const handleCopyQuestionnaireURL = () => {
        const clinicId = visitData.ClinicId;
        const uuid = visitData.uuid;
        const url = `${REACT_APP_FRONTEND_BASE_URL}/clinic/${clinicId}/Q/${uuid}`;
        navigator.clipboard.writeText(url);
    };

    const handleSubmit = async () => {
        setSaving(true);

        try {
            const categoryItems = items.map((categoryItem) => ({
                category: categoryItem.category,
                tasks: categoryItem.tasks.map((task) => ({
                    itemName: task.itemName,
                    status: task.status || 'unchecked',
                })),
            }));

            const requestData = {
                PatientId: patientId,
                uuid,
                checklist: categoryItems,
            };

            const response = await axios.post(
                `${REACT_APP_API_BASE_URL}/visit/checklist`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            } else {
                console.error('Failed to save checklist');
            }
        } catch (error) {
            console.error('Error occurred while saving checklist:', error);
        } finally {
            setSaving(false);
        }
    };


    return (
        <div>
            <div className="flex justify-between dark:border-strokedark py-3">
                <div className="flex items-center">
                    <h3 className="text-xl font-semibold text-black dark:text-white">
                        CheckList
                    </h3>
                    <ToolbarButton text="Collapse" icon={<ChevronDown className="w-4 h-4" />} onClick={handleCollapse} />
                </div>
                <div className="items-end">
                <ToolbarButton text="Copy Questionnaire URL" icon={<BookKey className="w-4 h-4" />} onClick={handleCopyQuestionnaireURL} />

                    <ToolbarButton text="Add Item" icon={<Plus className="w-4 h-4" />} onClick={handleAddDialogOpen} />
                    <ToolbarButton text="Save" icon={success ? <Check className="w-4 h-4 text-primaryColor-500 text-medium" /> : <Save className="w-4 h-4" />} onClick={handleSubmit} />
                </div>
            </div>
            <div className="border-b pb-2">
                <p className="inline text-sm text-gray-800 font-medium dark:text-white">
                    Visit Date:</p>
                <p className="pl-2 inline text-sm text-gray-800 dark:text-white">
                    {visitDate}</p>
            </div>

            {showAddDialog && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="w-1/3 bg-white rounded-lg p-8 z-20 dark:bg-gray-800">
                        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                            Add Checklist Item
                        </h2>
                        <div className="mb-4">
                            <label className={textBoldStyle}>Category:</label>
                            <select
                                className={inputSelectorStyle}
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                            >
                                <option value="" disabled>
                                    Select a category
                                </option>
                                {items.map((categoryItem, categoryIndex) => (
                                    <option key={categoryIndex} value={categoryItem.category}>
                                        {categoryItem.category}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className={textBoldStyle}>Item Name:</label>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                className={inputSelectorStyle}
                                placeholder="Enter item name"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={handleAddNewItem}
                                className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                            >
                                Add
                            </button>
                            <button
                                onClick={handleAddDialogClose}
                                className="text-gray-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {!collapsed && (
                <div className="flex space-x-4 mt-4">
                    {items.map((categoryItem, categoryIndex) => (
                        <div className="w-54" key={categoryItem.category}>
                            <ul>
                                <h4 className="text-lg font-bold text-gray-800 dark:text-white">
                                    {categoryItem.category}
                                </h4>
                                {categoryItem.tasks.map((task, taskIndex) => (
                                    <li key={taskIndex} className="space-y-1.5 pb-2">
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                className={checkboxStyle}
                                                checked={task.status === 'checked'}
                                                onChange={() => toggleItem(categoryIndex, taskIndex)}
                                            />
                                            <label
                                                htmlFor={`task-${categoryIndex}-${taskIndex}`}
                                                className={textStyle}
                                            >
                                                {task.itemName}
                                            </label>
                                            <button
                                                onClick={() => removeItem(categoryIndex, taskIndex)}
                                                className="pl-2 text-right hover-text-primaryColor-500"
                                            >
                                                <X className="h-3 w-3" />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CheckList;