import React, { Link } from 'react-router-dom';
import { ArrowLeft, ListTree } from 'lucide-react'; // Import the chevron-left icon
import Tooltip from './Tooltip';

interface BreadcrumbPatientProps {
  name: string;
  nric: string;
  daStatus: string;
  daName: string;
  age: string;
  gender: string;
  race: string;
}

const BreadcrumbPatient = ({ name, nric, daStatus, daName, age, gender, race }: BreadcrumbPatientProps) => {
  return (
    <div className="pb-10">
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      {/* Wrap the back button and h2 in a flex container */}
      <div className="flex items-center gap-4"> {/* Adjust the gap here */}
        <Link to="#" onClick={() => window.history.back()} className="text-primaryColor-500 hover:bg-primaryColor-500 hover:text-white px-2 py-2 rounded-full">
          <Tooltip text="Previous Page">
            <ArrowLeft size={20} />
          </Tooltip>
        </Link>
        <h2 className="text-title-md2 font-semibold text-black dark:text-white">
          {name} 
        </h2>
        <Link to="/list" className="text-primaryColor-500 hover:bg-primaryColor-500 hover:text-white px-2 py-2 rounded-full">
          <Tooltip text="Patient List">
            <ListTree size={20} />
          </Tooltip>
        </Link>
        {daStatus === "NKDA" ? ( // Render daStatus when it's "NKDA"
          <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
            {daStatus}
          </span>
        ) : (
          <div>
            <span className="bg-fuchsia-100 text-fuchsia-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-fuchsia-900 dark:text-fuchsia-300">
              DA: {daName}
            </span>
          </div>
        )}
      </div>

    </div>
          <div className="flex items-center"> {/* Adjust the gap here */}
          <span className="text-sm text-black dark:text-gray-400 pl-13 pr-2">NRIC:</span>
          <span className="text-sm  text-gray-500 dark:text-white">{nric}</span>
          <span className="text-sm text-black dark:text-gray-400 pl-4 pr-2">Age:</span>
          <span className="text-sm  text-gray-500 dark:text-white">{age}</span>
          <span className="text-sm text-black dark:text-gray-400 pl-4 pr-2">Race:</span>
          <span className="text-sm  text-gray-500 dark:text-white">{race}</span>
          <span className="text-sm text-black dark:text-gray-400 pl-4 pr-2">Gender:</span>
          <span className="text-sm  text-gray-500 dark:text-white">{gender}</span>
        </div>
        </div>
  );
};

export default BreadcrumbPatient;
