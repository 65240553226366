import React, { useState, useEffect } from 'react';

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const inputDateStyle = 'text-sm w-full rounded-lg border-[1px] border-gray-200 bg-transparent py-3 px-2 outline-none transition disabled:cursor-default disabled:bg-whiter hover-border-[1px] hover:border-blue-600 active:border-blue-600 dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary-500';

function DateRangeFilter({
  applyDateFilter,
  closeDropdown
}) {
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  // Function to get the current date in the format 'YYYY-MM-DD'
  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split('T')[0]; // returns the date in 'YYYY-MM-DD'
  };

  useEffect(() => {
    // Set maxDate to today's date by default
    setMaxDate(getCurrentDate());
  }, []);

  const handleApplyDateFilter = () => {
    applyDateFilter(minDate, maxDate);  // Pass string date values to the parent
    closeDropdown(); // Close the dropdown in the parent component
  };

  const handleClearFilter = () => {
    // Apply the reset filter to parent component
    applyDateFilter(null, null);
    closeDropdown(); // Close the dropdown in the parent component
  };

  return (
    <div className="w-full px-4 py-4 relative">
      <div className="flex flex-row space-x-2">
        <div>
          <label className={textStyle}>Min Range</label>
          <input
            type="date"
            value={minDate}
            onChange={(e) => setMinDate(e.target.value)}
            placeholder="Min Date"
            className={inputDateStyle}
            aria-label="Min Date Range" 
          />
        </div>
        <div>
          <label className={textStyle}>Max Range</label>
          <input
            type="date"
            value={maxDate}
            onChange={(e) => setMaxDate(e.target.value)}
            placeholder="Max Date"
            className={inputDateStyle}
            aria-label="Max Date Range" 
          />
        </div>
      </div>
      <div className="pt-4 w-full">
        <div className="flex flex-row space-x-2">
          <button
            className="w-1/2 h-12 text-primaryColor-500 inline-flex justify-center items-center hover:text-white border border-primaryColor-500 hover:bg-primaryColor-500 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primaryColor-500 dark:text-white dark:hover:text-white dark:hover:bg-primaryColor-600 dark:focus:ring-primaryColor-900"
            onClick={handleApplyDateFilter}
          >
            Apply Date Range
          </button>
          <button
            className="w-1/2 text-primaryColor-500 inline-flex justify-center items-center hover:text-white border border-primaryColor-500 hover:bg-primaryColor-500 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primaryColor-500 dark:text-white dark:hover:text-white dark:hover:bg-primaryColor-600 dark:focus:ring-primaryColor-900"
            onClick={handleClearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>
    </div>
  );
}

export default DateRangeFilter;
