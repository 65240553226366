import { useState, useEffect } from 'react';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient
import convertUTCToSGTimestamp from '../function/convertUTCToSGTimestamp';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const preprocessSnomedCode = (snomedCodeObject) => {
  // Convert the object into an array of entries
  const entries = Object.entries(snomedCodeObject);

  // Map each entry to an object with code and description properties
  const snomedCodeArray = entries.map(([code, description]) => ({
    code,
    description,
  }));

  return snomedCodeArray;
};

const useFormData = (uuid) => {
  const [error, setError] = useState(null);

  const [pastMedicalHistory, setPastMedicalHistory] = useState(null);
  const [pmHxFetched, setPmHxFetched] = useState(false);

  const [snomedCode, setSnomedCode] = useState(null);
  const [snomedCodeFetched, setSnomedCodeFetched] = useState(false);

  const [lifestyleHistory, setLifestyleHistory] = useState(null);
  const [lifestyleHxFetched, setLifestyleHxFetched] = useState(false);

  const [familyHistory, setFamilyHistory] = useState(null);
  const [familyHxFetched, setFamilyHxFetched] = useState(false);

  const [internalNotes, setInternalNotes] = useState(null);
  const [internalNotesFetched, setInternalNotesFetched] = useState(false);

  const [physicalExam, setPhysicalExam] = useState(null);
  const [physicalExamFetched, setPhysicalExamFetched] = useState(false);

  const [labs, setLabs] = useState(null);
  const [labFetched, setLabFetched] = useState(false);

  const [bodyMeasurements, setBodyMeasurements] = useState(null); // Add new state for body measurements
  const [bodyMeasurementsFetched, setBodyMeasurementsFetched] = useState(false);

  const [matchedLabConditions, setmatchedLabConditions] = useState(null);

  const [recommendation, setRecommendation] = useState(null);
  const [recommendationFetched, setRecommendationFetched] = useState(false);

  const [verifiedAITags, setverifiedAITags] = useState(null);

  const [vision, setVision] = useState(null);
  const [visionCreatedDate, setVisionCreatedDate] = useState(null);
  const [visionFetched, setVisionFetched] = useState(false);

  const [tonometry, setTonometry] = useState(null);
  const [tonometryCreatedDate, setTonometryCreatedDate] = useState(null);
  const [tonometryFetched, setTonometryFetched] = useState(false);

  const [audiogram, setAudiogram] = useState(null);
  const [audiogramCreatedDate, setAudiogramCreatedDate] = useState(null);
  const [audiogramFetched, setAudiogramFetched] = useState(false);

  const [colonoscopy, setColonoscopy] = useState(null);
  const [colonoscopyCreatedDate, setColonoscopyCreatedDate] = useState(null);
  const [colonoscopyFetched, setColonoscopyFetched] = useState(false);

  const [framingham, setFramingham] = useState(null);
  const [framinghamCreatedDate, setFraminghamCreatedDate] = useState(null);
  const [framinghamFetched, setFraminghamFetched] = useState(false);

  const [loadingFormData, setLoadingFormData] = useState(true);
  const queryClient = useQueryClient(); // Get the query client instance

  // A function to calculate tab completion status
  const calculateTabCompletionStatus = () => {
    return {
      patient: 0, // always 0
      details: 0, // always 0
      screening:
        pmHxFetched &&
        lifestyleHxFetched &&
        familyHxFetched &&
        physicalExamFetched &&
        bodyMeasurementsFetched
          ? 3
          :   (pmHxFetched || lifestyleHxFetched || familyHxFetched || physicalExamFetched || bodyMeasurementsFetched)? 2 : 1,
      labs: labFetched ? 3 : 1,
      testsprocedures: framinghamFetched ? 3 : (framinghamFetched || visionFetched || tonometryFetched || audiogramFetched) ? 2 : 1,
      report: recommendationFetched ? 3 : 1, // Set report tab to 2 if recommendation is fetched
    };
  };

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/forms/uuid/${uuid}`,
          {
            withCredentials: true, // Include credentials
            headers: {
              'Content-Type': 'application/json', // Set your headers if needed
              // Add any other necessary headers
            },
          },
        );

        const { data } = response;
        // console.log('Form data fetched from hook', data);

        const pmHxEntry = data.find(
          (entry) => entry.formType === 'PastMedicalHx',
        );
        if (pmHxEntry) {
          setPastMedicalHistory(pmHxEntry.formContent.pastMedicalHistory);
          setPmHxFetched(true);
          queryClient.setQueryData(['form', uuid, 'PastMedicalHx'], pastMedicalHistory); // Store the data in cache
        }

        const snomedEntry = data.find(
          (entry) => entry.formType === 'PastMedicalHx',
        );
        if (snomedEntry) {
          setSnomedCode(snomedEntry.formContent.snomedCodeDetails);
          setSnomedCodeFetched(true);
          queryClient.setQueryData(['form', uuid, 'Snomed'], snomedCode); // Cache the snomed code
        }

        const lifestyleHxEntry = data.find(
          (entry) => entry.formType === 'LifestyleHx',
        );
        if (lifestyleHxEntry) {
          setLifestyleHistory(lifestyleHxEntry.formContent);
          setLifestyleHxFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'LifestyleHx'],
            lifestyleHxEntry.formContent,
          ); // Cache the lifestyle history
        }

        const fmHxEntry = data.find((entry) => entry.formType === 'FamilyHx');
        if (fmHxEntry) {
          setFamilyHistory(fmHxEntry.formContent);
          setFamilyHxFetched(true);
          queryClient.setQueryData(['form', uuid, 'FamilyHx'], fmHxEntry.formContent); // Cache the family history
        }

        const internalNotesEntry = data.find(
          (entry) => entry.formType === 'InternalNotes',
        );
        if (internalNotesEntry) {
          setInternalNotes(internalNotesEntry.formContent);
          setInternalNotesFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'InternalNotes'],
            internalNotesEntry.formContent,
          ); // Cache the internal notes
        }

        const physicalExamEntry = data.find(
          (entry) => entry.formType === 'PhysicalExam',
        );
        if (physicalExamEntry) {
          setPhysicalExam(physicalExamEntry.formContent);
          setPhysicalExamFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'PhysicalExam'],
            physicalExamEntry.formContent,
          ); // Cache the physical exam
        }

        const labEntry = data.find((entry) => entry.formType === 'Labs');
        if (labEntry) {
          setLabs(labEntry.formContent);
          setLabFetched(true);
          queryClient.setQueryData(['form', uuid, 'Labs'], labEntry.formContent); // Cache the lab results
        }

        const recommendationEntry = data.find(
          (entry) => entry.formType === 'Recommendation',
        );
        if (recommendationEntry) {
          // console.log("Recommendation Entry Content:", recommendationEntry.formContent);
          setRecommendation(recommendationEntry.formContent);
          setRecommendationFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'Recommendation'],
            recommendationEntry.formContent,
          ); // Cache the recommendation
        }

        const matchedLabConditionsEntry = data.find(
          (entry) => entry.formType === 'ProcessedLabConditions',
        );
        if (matchedLabConditionsEntry) {
          // console.log("matchedLabConditionsEntry Entry Content:", matchedLabConditionsEntry.formContent);
          setmatchedLabConditions(matchedLabConditionsEntry.formContent);
          queryClient.setQueryData(
            ['form', uuid, 'ProcessedLabConditions'],
            matchedLabConditionsEntry.formContent,
          ); // Cache the matched lab conditions
        }

        const verifiedAITagsEntry = data.find(
          (entry) => entry.formType === 'RecTags',
        );
        if (verifiedAITagsEntry) {
          setverifiedAITags(verifiedAITagsEntry.formContent);
          queryClient.setQueryData(
            ['form', uuid, 'VerifiedAIRecTags'],
            verifiedAITagsEntry.formContent,
          ); // Cache the verified AI tags
        }

        const VisionEntry = data.find((entry) => entry.formType === 'Vision');
        if (VisionEntry) {
          setVision(VisionEntry.formContent);
          setVisionCreatedDate(
            VisionEntry?.createdAt ? convertUTCToSGTimestamp(VisionEntry.createdAt) : null
          ); // Convert timestamp to SGT
          setVisionFetched(true);
          queryClient.setQueryData(['form', uuid, 'Vision'], VisionEntry.formContent); // Cache the vision
        }

        const tonometryEntry = data.find(
          (entry) => entry.formType === 'Tonometry',
        );
        if (tonometryEntry) {
          setTonometry(tonometryEntry.formContent);
          setTonometryCreatedDate(
            tonometryEntry?.createdAt ? convertUTCToSGTimestamp(tonometryEntry.createdAt) : null
          ); // Convert timestamp to SGT
          setTonometryFetched(true);
          queryClient.setQueryData(['form', uuid, 'Tonometry'], tonometryEntry.formContent); // Cache the tonometry
        }

        const audiogramEntry = data.find(
          (entry) => entry.formType === 'Audiogram',
        );
        if (audiogramEntry) {
          setAudiogram(audiogramEntry.formContent);
          setAudiogramCreatedDate(
            audiogramEntry?.createdAt ? convertUTCToSGTimestamp(audiogramEntry.createdAt) : null
          ); // Convert timestamp to SGT
          setAudiogramFetched(true);
          queryClient.setQueryData(['form', uuid, 'Audiogram'], audiogramEntry.formContent); // Cache the audiogram
        }

        const colonoscopyEntry = data.find(
          (entry) => entry.formType === 'Colonoscopy',
        );
        if (colonoscopyEntry) {
          setColonoscopy(colonoscopyEntry.formContent);
          setColonoscopyCreatedDate(
            colonoscopyEntry?.createdAt ? convertUTCToSGTimestamp(colonoscopyEntry.createdAt) : null
          ); // Convert timestamp to SGT
          setColonoscopyFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'Colonoscopy'],
            colonoscopyEntry.formContent,
          ); // Cache the colonoscopy
        }

        const fraiminghamEntry = data.find(
          (entry) => entry.formType === 'Framingham',
        );
        if (fraiminghamEntry) {
          setFramingham(fraiminghamEntry.formContent);
          setFraminghamCreatedDate(
            fraiminghamEntry?.createdAt ? convertUTCToSGTimestamp(fraiminghamEntry.createdAt) : null
          ); // Convert timestamp to SGT
          setFraminghamFetched(true);
          queryClient.setQueryData(
            ['form', uuid, 'Framingham'],
            fraiminghamEntry.formContent,
          ); // Cache the framingham
        }

        setLoadingFormData(false);
      } catch (error) {
        console.error(error);
        setError('An error occurred while fetching form data.');
        setLoadingFormData(false);
      }
    };

    const fetchBodyMeasure = async () => {
      try {
        // Second API call for body measurements
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/measures/uuid/${uuid}`,
          {
            withCredentials: true,
          },
        );

        const data = response.data;

        const bodyMeasurementData = {
          height: data.height || '',
          weight: data.weight || '',
          bmi: data.bmi || '',
          systolicBp: data.systolicBp || '',
          diastolicBp: data.diastolicBp || '',
          heartrate: data.heartrate || '',
          bmiCategory: data.bmiCategory || '',
          bpCategory: data.bpCategory || '',
          waistcircum: data.waistcircum || '',
          restingmetabolism: data.restingmetabolism || '',
          bodyfat: data.bodyfat || '',
          visceralfat: data.visceralfat || '',
        };

        setBodyMeasurements(bodyMeasurementData);
        setBodyMeasurementsFetched(true);
        queryClient.setQueryData(
          ['form', uuid, 'BodyMeasurements'],
          bodyMeasurementData,
        );
      } catch (error) {
        console.error('Error fetching body measurements:', error);
        setError('An error occurred while fetching body measurements.');
      }
    };

    fetchFormData();
    fetchBodyMeasure();
    setLoadingFormData(false);

    // console.log("recommendation from hook", recommendation);
    // console.log("Hook internalNotes", internalNotes);
  }, [uuid, queryClient]);
  const tabCompletionStatus = calculateTabCompletionStatus();
queryClient.setQueryData(['form', uuid, 'TabCompletionStatus'], tabCompletionStatus);
    // console.log("tabCompletionStatus from cache", tabCompletionStatus);
 

return {
    pastMedicalHistory,
    pmHxFetched,
    snomedCode,
    snomedCodeFetched,
    lifestyleHistory,
    lifestyleHxFetched,
    familyHistory,
    familyHxFetched,
    internalNotes,
    internalNotesFetched,
    physicalExam,
    physicalExamFetched,
    labs,
    labFetched,
    recommendation,
    recommendationFetched,
    matchedLabConditions,
    verifiedAITags,
    vision,
    visionFetched,
    visionCreatedDate,
    tonometry,
    tonometryFetched,
    tonometryCreatedDate,
    audiogram,
    audiogramFetched,
    audiogramCreatedDate,
    colonoscopy,
    colonoscopyFetched,
    colonoscopyCreatedDate,
    framingham,
    framinghamFetched,
    framinghamCreatedDate,
    bodyMeasurements,
    bodyMeasurementsFetched,
    loadingFormData,
    tabCompletionStatus,
  };
};

export default useFormData;
