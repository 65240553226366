import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import ModalCommentEdit from '../modal/ModalCommentEdit';
import ToolbarButton from '../ToolbarButton';
import { Pencil } from 'lucide-react';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp';
import useUserDetailsCache from '../../hooks/useUserDetailsCache';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface Comment {
  id: number;
  commentType: string;
  commentContent: string;
  commentUuid: string;
  createdAt: string;
  updatedAt: string;
  MwUserId: number;
  PatientId: number;
  name: string;
  username: string | null;
  picture: string | null;
}

interface CommentListsProps {
  patientId: number;
}
type UserData = {
  id: number; // Add the current user's MwUserId
};

const CommentLists: React.FC<CommentListsProps> = ({ patientId }) => {
  // console.log('patientId', patientId);
  const [comments, setComments] = useState<Comment[]>([]);
  const [isModalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null); // State to store user data
  const [commentLimit, setCommentLimit] = useState(5); // Track the number of comments to display
  const { userDetails, clinicDetails, error, isLoading } = useUserDetailsCache(); // Correct usage of the custom hook

  const fetchComments = () => {
    fetch(`${REACT_APP_API_BASE_URL}/comments/${patientId}`, {
      credentials: 'include', // Include credentials (cookies) in the request
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch comments. Status: ${response.status}`,
          );
        }
        return response.json();
      })
      .then((commentsData: Comment[]) => {
        // console.log('Fetched comments:', commentsData);
        setComments(commentsData.reverse());
      })
      .catch((error) => {
        console.error('Error fetching comments:', error);
      });
  };
  useEffect(() => {
    fetchComments();
    if (userDetails) {
      setUserData(userDetails);
      // console.log('userDetails', userDetails);
    }
  }, [patientId]);

  // Function to load more comments
  const loadMoreComments = () => {
    setCommentLimit(commentLimit + 5); // Increase the comment limit by 5
  };

  // Function to handle opening the modal
  const openModal = () => {
    setModalOpen(true);
  };
  // Function to handle closing the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEditClick = (comment: Comment, index: number) => {
    setSelectedComment(comment); // Pass the entire comment object
    openModal();
    // console.log("Parent comment content:", comment.commentContent);
    // console.log("Parent comment index:", index);
  };

  const canEditComment = (comment: Comment) => {
    // Return true if the user's id matches the MwUserId in the comment
    return userData?.id === comment.MwUserId;
  };

  return (
    <div className="space-y-6">
      {comments.length > 0 &&
        comments.slice(0, commentLimit).map((comment, index) => (
          <div key={comment.id + index} className="flex items-start">
            <div className="h-8 w-8 rounded-full overflow-hidden mr-4">
              <img
                src={
                  comment.picture ||
                  'https://milkiway-public-images.s3.ap-southeast-1.amazonaws.com/default-user-image.png'
                }
                alt={comment.name}
                className="h-full w-full object-cover"
              />
            </div>
            <div className="w-full ">
              <div className="bg-gray-100 text-black rounded-lg p-4 flex-grow dark:bg-gray-800">
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <span className="font-semibold dark:text-gray-200">
                      {comment.name}
                    </span>
                    {comment.username && (
                      <span className="bg-primaryColor-50 text-primaryColor-500 text-xs font-medium px-2.5 py-0.5 rounded ml-2">
                        @ {comment.username}
                      </span>
                    )}
                  </div>
                  {canEditComment(comment) && ( // Check if the user can edit this comment
                    <div className="inline-block">
                      <ToolbarButton
                        text="Edit"
                        icon={<Pencil className="w-3 h-3" />}
                        onClick={() => handleEditClick(comment, index)} // Pass both the comment and index
                      />
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-600">
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className="prose prose-sm dark:prose-invert"
                  >
                    {comment.commentContent}
                  </ReactMarkdown>
                </div>
              </div>
              <p className="text-xs text-gray-600 dark:text-gray-200 pt-2 pl-2">
                {convertUTCToSGTimestamp(comment.createdAt)}
              </p>
            </div>
          </div>
        ))}

      {comments.length > commentLimit && ( // Display "Load More" button if there are more comments
        <div className="text-center">
          <button
            onClick={loadMoreComments}
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Load more
            </span>
          </button>
        </div>
      )}
      <ModalCommentEdit
        isOpen={isModalOpen}
        onClose={closeModal}
        comment={selectedComment}
        onSaveComment={fetchComments} // Pass the fetchComments function as a prop
      />
    </div>
  );
};

export default CommentLists;
