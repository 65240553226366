import { useQuery } from '@tanstack/react-query';

const defaultUserPicture = "https://milkiway-public-images.s3.ap-southeast-1.amazonaws.com/default-user-image.png";

// Define the data structure for the response
interface UserDetails {
  name: string;
  email: string;
  picture: string;
  id: number;
  role: string;
}

interface ClinicDetails {
  name: string;
  id: number;
}

interface UserResponse {
  user: {
    name: string;
    email: string;
    picture: string | null;
  };
  clinic: {
    clinicName: string;
    clinicId: string;
  };
  userId: string;
  role: {
    role: string;
  };
}

// Function to fetch user details
const fetchUserDetails = async (): Promise<{
  userDetails: UserDetails;
  clinicDetails: ClinicDetails;
}> => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user details');
  }

  const userData: UserResponse = await response.json();

  return {
    userDetails: {
      name: userData.user.name,
      email: userData.user.email,
      picture: userData.user.picture || defaultUserPicture,
      id: parseInt(userData.userId, 10), // Convert userId to number
      role: userData.role.role,
    },
    clinicDetails: {
      name: userData.clinic.clinicName,
      id: parseInt(userData.clinic.clinicId, 10), // Convert clinicId to number
    },
  };
};

// Custom hook to use cached user details
const useUserDetailsCache = () => {
  const queryResult = useQuery({
    queryKey: ['userDetails'], // Unique key for this query
    queryFn: fetchUserDetails,  // Function to fetch the data
    staleTime: 12 * 60 * 60 * 1000, // Cache data for 12 hours
  });
  return {
    userDetails: queryResult.data?.userDetails,
    clinicDetails: queryResult.data?.clinicDetails,
    error: queryResult.error,
    isLoading: queryResult.isLoading,
  };
  
};

export default useUserDetailsCache;
