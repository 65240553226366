import React, { useEffect, useState } from 'react';
import { AlertTriangle, X } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
import axios from 'axios';
import SearchBarPatientQuestionnaire from '../SearchBarPatientQuestionnaire';
import sanitizeInput from '../../function/sanitizeInput';
import formValidationConfig from '../../config/formValidationConfig.json';
import validateForm from '../../function/formValidator';
import validateMissingFields from '../../function/missingFieldValidator';
import useTemplateCache from '../../hooks/useTemplateCache';
import InfoPopup from '../alerts/InfoPopup';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ModalPatientRegistrationProps {
  isOpen: boolean;
  onClose: () => void;
  onRegister: () => void;
}

// Define FormData interface
interface FormData {
  name: string;
  nric: string;
  gender: string;
  race: string;
  birthDate: string;
  mobileNumber: string;
  email: string;
  streetAddress: string;
  zipcode: string;
  daStatus: string;
  daName: string;
  healthScreeningPackageUuid: string;
}

type FormErrorMessages = Partial<FormData>;

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

const raceOptions = [
  { value: 'Caucasian', label: 'Caucasian' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Eurasian', label: 'Eurasian' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Others', label: 'Others' },
];

const daStatusOptions = [
  { value: 'NKDA', label: 'NKDA' },
  { value: 'Has Drug Allergy', label: 'Has Drug Allergy' },
];

const textStyle = 'text-sm font-medium text-gray-900 dark:text-white';
const requiredAsteriskStyle = 'text-red-500 mx-1'; // Define the style for the red asterisk

const ModalPatientRegistration: React.FC<ModalPatientRegistrationProps> = ({
  isOpen,
  onClose,
  onRegister,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: null,
    nric: null,
    birthDate: null,
    gender: null,
    race: null,
    mobileNumber: null,
    email: null,
    streetAddress: null,
    zipcode: null,
    daStatus: null,
    daName: null,
    healthScreeningPackageUuid: null,
  });

  const [healthScreeningPackageOptions, setHealthScreeningPackageOptions] = useState([]);
  const [qUuid, setqUuid] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State to manage error message
  const [formErrorMessages, setFormErrorMessages] = useState<FormErrorMessages>(
    {
      name: undefined,
      nric: undefined,
      gender: undefined,
      race: undefined,
      birthDate: undefined,
      mobileNumber: undefined,
      email: undefined,
      streetAddress: undefined,
      zipcode: undefined,
      daStatus: undefined,
      daName: undefined,
      healthScreeningPackageUuid: undefined,
    },
  );

  const [errorBoxHighlight, setErrorBoxHighlight] = useState({
    name: false,
    nric: false,
    birthDate: false,
    mobileNumber: false,
    email: false,
    streetAddress: false,
    zipcode: false,
    gender: false,
    race: false,
    daStatus: false,
    healthScreeningPackageUuid: false,
  });

  const { data: templateData = {}, isLoading: templateLoading } = useTemplateCache();
 
  useEffect(() => {
    // Check if loading is false and HealthScreeningPackage exists
    if (!templateLoading && templateData['HealthScreeningPackage']) {
      const healthPackages = templateData['HealthScreeningPackage'];
      
      // Assuming healthPackages should be an array
      if (Array.isArray(healthPackages)) {
        setHealthScreeningPackageOptions(healthPackages);
      } else {
        console.error("Expected HealthScreeningPackage to be an array");
      }
    }
  }, [templateData, templateLoading]);

  const inputSelectorStyle = (fieldName) => `
    bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 
    ${errorBoxHighlight[fieldName] ? 'border-red-500' : 'border-gray-300'}
    block w-full`;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Update formData based on the input field name
        const updatedFormData = name === 'healthScreeningPackage'
            ? { ...formData, healthScreeningPackageUuid: value }
            : { ...formData, [name]: value };
    
        // Set the updated form data
        setFormData(updatedFormData);
    
        // Log updated form data
        // console.log("Updated form data:", updatedFormData);
    
        // Clear the error message for the current field when it changes
        setFormErrorMessages({ ...formErrorMessages, [name]: '' });
    
        // Log form error messages after clearing
        // console.log("Cleared error messages:", { ...formErrorMessages, [name]: '' });
    
        // Clear errorBoxHighlight for the current field
        setErrorBoxHighlight({ ...errorBoxHighlight, [name]: false });
    
        // Log error box highlight after clearing
        // console.log("Cleared error box highlight:", { ...errorBoxHighlight, [name]: false });
    
        // Validate form fields directly without delay
        validateForm(
            updatedFormData,
            formValidationConfig,
            setFormErrorMessages,
            Object.keys(formData),
        );
    
        // Additional logic to clear validation check if input is in the right format
        const fieldConfig = formValidationConfig.fields.find(
            (field) => field.name === name,
        );
        if (
            fieldConfig &&
            fieldConfig.regex &&
            new RegExp(fieldConfig.regex).test(value)
        ) {
            setFormErrorMessages({ ...formErrorMessages, [name]: '' });
        }
    
        // Log updated form error messages
        // console.log("Updated form error messages:", formErrorMessages);
    };
    

  const handleSubmit = async () => {
    // Call validateMissingFields to check for missing fields
    const fieldsToCheck = Object.keys(formData); // Get the field names from the formData object
    const missingFields = validateMissingFields(
      formData,
      formValidationConfig,
      fieldsToCheck,
    );

    // Check if there are any missing fields
    if (missingFields.length > 0) {
      // Display alert for missing fields
      const errorObject = {};
      missingFields.forEach((field) => {
        errorObject[field.name] = `${field.label} is required.`;
      });
      setFormErrorMessages(errorObject);

      // Highlight the input fields with missing data
      const highlightState = {};
      missingFields.forEach((field) => {
        highlightState[field.name] = true;
      });
      setErrorBoxHighlight({ ...errorBoxHighlight, ...highlightState });

      return;
    }

    const uuid = qUuid || uuidv4(); // If qUuid is available, use its value, otherwise generate a new UUID

    const data = {
      name: formData.name,
      nric: formData.nric.toUpperCase(), // Convert NRIC to uppercase,
      birthDate: formData.birthDate,
      gender: formData.gender,
      race: formData.race,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      streetAddress: formData.streetAddress,
      zipcode: formData.zipcode,
      daStatus: formData.daStatus,
      daName: formData.daName,
    };
    // console.log("Data to be Sent", data);

    // API to (1) add a row to the Patients table (2) associate the patient with the clinic in PatientClinic table
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/patients/register`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );

      if (response.status === 200 || response.status === 201) {
        // The request was successful, handle the success case here
        const { PatientId } = response.data;
        // console.log("Patient data submitted successfully");
        // console.log("Patient ID:", PatientId);

        // Now that you have PatientId, you can use it in the visit object
        const visit = {
          uuid,
          healthScreeningPackageUuid: formData.healthScreeningPackageUuid,
          PatientId: PatientId,
          qAssociated: !!qUuid, // Set qAssociated to true if qUuid is available
        };
        // console.log("Visit data posted:", visit);
        // API to
        // (1) create a visit row
        // (2) update patientId upon questionnaire association in Questionnaire table
        // (3) bulk add questionnaire data to relevant sections in history under Screening tab

        try {
          const visitResponse = await axios.post(
            `${REACT_APP_API_BASE_URL}/visit/`,
            visit,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            },
          );

          // Handle the response for the visit POST request here
          if (visitResponse.status === 201) {
            // console.log("Visit data submitted successfully");
          } else {
            // Handle other status codes for the visit request
            console.error(
              'Received an unexpected status code for the visit request:',
              visitResponse.status,
            );
          }
        } catch (error) {
          // Handle any errors for the visit request here
          console.error('Error submitting visit data:', error);
        }

        setFormData({
          name: '',
          nric: '',
          birthDate: '',
          gender: '',
          race: '',
          mobileNumber: '',
          email: '',
          streetAddress: '',
          zipcode: '',
          daStatus: '',
          daName: '',
          healthScreeningPackage: '',
        });

        // Pass the uuid back to the parent component
        onRegister(uuid);
        // Close the modal
        // ****************** onClose();
      } else {
        // Handle other status codes if necessary
        console.error('Received an unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error submitting patient data:', error);
    }
  };

  const handlePatientDataSelect = (patientData) => {
    // Set the selected patient data in the state or perform actions with the data
    // console.log("Selected patient data:", patientData);
    // Example of setting the received data into state variables
    setFormData({
      name: patientData.name,
      nric: patientData.nric,
      birthDate: patientData.birthDate,
      gender: patientData.gender,
      race: patientData.race,
      mobileNumber: patientData.mobileNumber,
      email: patientData.email,
      streetAddress: patientData.streetAddress,
      zipcode: patientData.zipcode,
      daStatus: patientData.daStatus,
      daName: patientData.daName,
      healthScreeningPackageUuid: patientData.healthScreeningPackageUuid,
    });
    setqUuid(patientData.qUuid);
  };

  return (
    <div
      className={`fixed inset-0 ${isOpen ? '' : 'hidden'}`}
      style={{ zIndex: 1000 }}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-black bg-opacity-75 absolute inset-0 z-10"></div>
        <div className="w-8/12 bg-white p-8 rounded-lg z-20 dark:bg-gray-800">
          <div className="flex justify-between items-center">
            <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
              Add patient details
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-primary-500 cursor-pointer relative bottom-8 left-2"
            >
              <X className="inline-block w-4 h-4" />
            </button>
          </div>

          <div className="space-y-2 pt-2 mb-4 sm:gap-10 sm:mb-5 border-b">
            <SearchBarPatientQuestionnaire
              onPatientDataSelect={handlePatientDataSelect}
              setErrorMessage={setErrorMessage}
            />
          </div>
          {errorMessage &&
<InfoPopup color="red" boldText="Error!" text={errorMessage} icon={AlertTriangle} />
}
          <div className="flex">
            <div className="flex w-1/4 space-x-6 px-4">
              <div className="w-full space-y-4">
                <div>
                  <label className={textStyle}>
                    Name<span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={sanitizeInput(formData.name)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('name')}
                  />
                  {formErrorMessages.name && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.name}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    NRIC<span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="nric"
                    value={sanitizeInput(formData.nric)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('nric')}
                  />
                  {formErrorMessages.nric && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.nric}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-1/4 space-x-6 px-4">
              <div className="w-full space-y-4">
                <div>
                  <label className={textStyle}>
                    Gender
                    <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <select
                    name="gender"
                    value={sanitizeInput(formData.gender)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('gender')}
                  >
                    {genderOptions.map((option) => (
                      <option
                        key={option.value}
                        value={sanitizeInput(option.value)}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>

                  {formErrorMessages.gender && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.gender}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    Race<span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <select
                    name="race"
                    value={sanitizeInput(formData.race)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('race')}
                  >
                    <option value="">Select Race</option>
                    {raceOptions.map((option) => (
                      <option
                        key={option.value}
                        value={sanitizeInput(option.value)}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {formErrorMessages.race && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.race}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    Birthdate
                    <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="date"
                    name="birthDate"
                    value={sanitizeInput(formData.birthDate)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('birthDate')}
                  />
                  {formErrorMessages.birthDate && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.birthDate}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-1/4 space-x-6 px-4">
              <div className="w-full space-y-4">
                <div>
                  <label className={textStyle}>
                    Mobile Number
                    <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={sanitizeInput(formData.mobileNumber)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('mobileNumber')}
                  />
                  {formErrorMessages.mobileNumber && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.mobileNumber}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    {' '}
                    Email <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={sanitizeInput(formData.email)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('email')}
                  />
                  {formErrorMessages.email && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.email}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    Street Address
                    <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={sanitizeInput(formData.streetAddress)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('streetAddress')}
                  />
                  {formErrorMessages.streetAddress && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.streetAddress}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>
                    Zip Code<span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <input
                    type="text"
                    name="zipcode"
                    value={sanitizeInput(formData.zipcode)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('zipcode')}
                  />
                  {formErrorMessages.zipcode && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.zipcode}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-1/4 space-x-6 px-4">
              <div className="w-full space-y-4">
                <div>
                  <label className={textStyle}>
                    Drug Allergy Status
                    <span className={requiredAsteriskStyle}>*</span>
                  </label>
                  <select
                    name="daStatus"
                    value={sanitizeInput(formData.daStatus)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('daStatus')}
                  >
                    <option value="">Select a status</option>{' '}
                    {/* Placeholder */}
                    {daStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={sanitizeInput(option.value)}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {formErrorMessages.daStatus && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.daStatus}
                    </div>
                  )}
                </div>
                <div>
                  <label className={textStyle}>Allergic to</label>
                  <input
                    type="text"
                    name="daName"
                    placeholder="Name of medication"
                    value={sanitizeInput(formData.daName)}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    className={inputSelectorStyle('daName')}
                  />
                  {formErrorMessages.daName && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.daName}
                    </div>
                  )}
                </div>
                <div>
<label className={textStyle}>
  Health Screening Package
  <span className={requiredAsteriskStyle}>*</span>
</label>
<select
  name="healthScreeningPackage"
  value={formData.healthScreeningPackageUuid} // Ensure this references the correct field
  onChange={handleInputChange}
  className={inputSelectorStyle('healthScreeningPackage')}
>
  <option value="">Select a package</option> {/* Placeholder */}
  {healthScreeningPackageOptions.length > 0 ? (
    healthScreeningPackageOptions
/*       .sort((a, b) => {
        const packageA = a.healthScreeningPackageName.toLowerCase();
        const packageB = b.healthScreeningPackageName.toLowerCase();
        if (packageA < packageB) return -1;
        if (packageA > packageB) return 1;
        return 0;
      }) */
      .map((option) => (
        <option key={option.healthScreeningPackageUuid} value={option.healthScreeningPackageUuid}>
          {option.healthScreeningPackageName}
        </option>
      ))
  ) : (
    <option disabled>No packages available</option> // Option when no packages are available
  )}
</select>

                  {formErrorMessages.healthScreeningPackageUuid && (
                    <div className="text-red-500 text-sm">
                      {formErrorMessages.healthScreeningPackageUuid}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            type="button"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalPatientRegistration;
