import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchTemplates = async () => {
  const response = await axios.get(`${REACT_APP_API_BASE_URL}/templates`, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const organizedData: Record<string, any> = {};
  response.data.forEach((template: { templateType: string; templateContent: any }) => {
    const { templateType, templateContent } = template;
    organizedData[templateType] = templateContent;
  });

  return organizedData;
};

const useTemplateCache = () => {
  const query = useQuery({
    queryKey: ['templates'],
    queryFn: fetchTemplates,
    staleTime: 12 * 60 * 60 * 1000, // Cache data for 12 hours
  });

  // Log fetch source
  // if (query.isFetching) {
  //   console.log('Fetching fresh data from the server...');
  // } else if (!query.isStale) {
  //   console.log('Serving data from the cache...');
  // }

  return query;
};

export default useTemplateCache;
